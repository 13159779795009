import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

//businessType

export const BUISNESS_CREATE_REQUEST = "BUISNESS_CREATE_REQUEST";
export const BUISNESS_CREATE_SUCCESS = "BUISNESS_CREATE_SUCCESS";
export const BUISNESS_CREATE_FAILURE = "BUISNESS_CREATE_FAILURE";

export const BUISNESS_UPDATE_REQUEST = "BUISNESS_UPDATE_REQUEST";
export const BUISNESS_UPDATE_SUCCESS = "BUISNESS_UPDATE_SUCCESS";
export const BUISNESS_UPDATE_FAILURE = "BUISNESS_UPDATE_FAILURE";

export const BUISNESS_LIST_REQUEST = "BUISNESS_LIST_REQUEST";
export const BUISNESS_LIST_SUCCESS = "BUISNESS_LIST_SUCCESS";
export const BUISNESS_LIST_FAILURE = "BUISNESS_LIST_FAILURE";

export const BUISNESS_DELETE_REQUEST = "BUISNESS_DELETE_REQUEST";
export const BUISNESS_DELETE_SUCCESS = "BUISNESS_DELETE_SUCCESS";
export const BUISNESS_DELETE_FAILURE = "BUISNESS_DELETE_FAILURE";


// later uncomment this code when api is ready

// export const DELIVERY_DISTANCE_RANGE_LIST_SUCCESS = "DELIVERY_DISTANCE_RANGE_LIST_SUCCESS";
// export const DELIVERY_DISTANCE_RANGE_LIST_FAILURE = "DELIVERY_DISTANCE_RANGE_LIST_FAILURE";
// export const DELIVERY_DISTANCE_RANGE_LIST_REQUEST = "DELIVERY_DISTANCE_RANGE_LIST_REQUEST";

// export const DELIVERY_DISTANCE_RANGE_CREATE_SUCCESS = "DELIVERY_DISTANCE_RANGE_CREATE_SUCCESS";
// export const DELIVERY_DISTANCE_RANGE_CREATE_FAILURE = "DELIVERY_DISTANCE_RANGE_CREATE_FAILURE";
// export const DELIVERY_DISTANCE_RANGE_CREATE_REQUEST = "DELIVERY_DISTANCE_RANGE_CREATE_REQUEST";

// export const DELIVERY_DISTANCE_RANGE_UPDATE_SUCCESS = "DELIVERY_DISTANCE_RANGE_UPDATE_SUCCESS";
// export const DELIVERY_DISTANCE_RANGE_UPDATE_FAILURE = "DELIVERY_DISTANCE_RANGE_UPDATE_FAILURE";
// export const DELIVERY_DISTANCE_RANGE_UPDATE_REQUEST = "DELIVERY_DISTANCE_RANGE_UPDATE_REQUEST";

// export const DELIVERY_DISTANCE_RANGE_DELETE_SUCCESS = "DELIVERY_DISTANCE_RANGE_DELETE_SUCCESS";
// export const DELIVERY_DISTANCE_RANGE_DELETE_FAILURE = "DELIVERY_DISTANCE_RANGE_DELETE_FAILURE";
// export const DELIVERY_DISTANCE_RANGE_DELETE_REQUEST = "DELIVERY_DISTANCE_RANGE_DELETE_REQUEST";

// buisness create

export const createBuisnessDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BUISNESS_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/create/business/type/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BUISNESS_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BUISNESS_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// buisness update

export const updateBuisnessDropdown = (id, dropdown, setUpdateModalShow,setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BUISNESS_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/update/business/type/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BUISNESS_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({})
    } catch (error) {
      errorHandling(dispatch, BUISNESS_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({})
    }
  };
};

// buisness list

export const buisnessDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: BUISNESS_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/get/all/business/type/name/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BUISNESS_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, BUISNESS_LIST_FAILURE, error);
    }
  };
};

// buisness delete

export const deleteBuisnessDropdown = (id, setDeleteModal,setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BUISNESS_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/delete/business/type/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BUISNESS_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData()
    } catch (error) {
      errorHandling(dispatch, BUISNESS_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData()
    }
  };
};



// later uncomment this code when api is ready

// export const createDeliveryDistanceDropdown = (dropdown, setModalShow, setFormData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: BUISNESS_CREATE_REQUEST });
//       const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/create/business/type/name/v1`;
//       const response = await axios.post(url, dropdown, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       });
//       dispatch({
//         type: BUISNESS_CREATE_SUCCESS,
//         payload: response.data,
//       });
//       setModalShow(false);
//       setFormData({});
//     } catch (error) {
//       errorHandling(dispatch, BUISNESS_CREATE_FAILURE, error);
//       setModalShow(false);
//       setFormData({});
//     }
//   };
// };

// export const updateDeliveryDistanceDropdown = (id, dropdown, setUpdateModalShow,setFormData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: BUISNESS_UPDATE_REQUEST });
//       const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/update/business/type/name/${id}/v1`;
//       const response = await axios.put(url, dropdown, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       });
//       dispatch({
//         type: BUISNESS_UPDATE_SUCCESS,
//         payload: response.data,
//       });
//       setUpdateModalShow(false);
//       setFormData({})
//     } catch (error) {
//       errorHandling(dispatch, BUISNESS_UPDATE_FAILURE, error);
//       setUpdateModalShow(false);
//       setFormData({})
//     }
//   };
// };

// export const ListDeliveryDistanceDropdown = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: BUISNESS_LIST_REQUEST });
//       const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/get/all/business/type/name/for/admin/v1`;
//       const response = await axios.get(url, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       });
//       dispatch({
//         type: BUISNESS_LIST_SUCCESS,
//         payload: response.data,
//       });
//     } catch (error) {
//       errorHandling(dispatch, BUISNESS_LIST_FAILURE, error);
//     }
//   };
// };

// export const deleteDeliveryDistanceDropdown = (id, setDeleteModal,setFormData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: BUISNESS_DELETE_REQUEST });
//       const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/delete/business/type/name/${id}/v1`;
//       const response = await axios.delete(url, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       });
//       dispatch({
//         type: BUISNESS_DELETE_SUCCESS,
//         payload: response.data,
//       });
//       setDeleteModal(false);
//       setFormData()
//     } catch (error) {
//       errorHandling(dispatch, BUISNESS_DELETE_FAILURE, error);
//       setDeleteModal(false);
//       setFormData()
//     }
//   };
// };