import * as vendorDropdownAction from "./vendorDropdown.action";

export const vendorDropdownFeatureKey = "vendorDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // BUISNESS create

    case vendorDropdownAction.BUISNESS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //BUISNESS update

    case vendorDropdownAction.BUISNESS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // BUISNESS list

    case vendorDropdownAction.BUISNESS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorDropdownAction.BUISNESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vendorDropdownAction.BUISNESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // BUISNESS delete

    case vendorDropdownAction.BUISNESS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

      // later uncomment this code when api are ready

      // DELIVERY DISTANCE create
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_CREATE_REQUEST:
      //   return {
      //     ...state,
      //     loading: true,
      //     isStatusUpdate: false,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_CREATE_SUCCESS:
      //   return {
      //     ...state,
      //     loading: false,
      //     isStatusUpdate: true,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_DELETE_FAILURE:
      //   return {
      //     ...state,
      //     loading: false,
      //     errorMsg: payload,
      //     list: [],
      //     isStatusUpdate: false,
      //   };
  
      // //DELIVERY DISTANCE update
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_UPDATE_REQUEST:
      //   return {
      //     ...state,
      //     loading: true,
      //     isStatusUpdate: false,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_UPDATE_SUCCESS:
      //   return {
      //     ...state,
      //     loading: false,
      //     isStatusUpdate: true,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_UPDATE_FAILURE:
      //   return {
      //     ...state,
      //     loading: false,
      //     errorMsg: payload,
      //     list: [],
      //     isStatusUpdate: false,
      //   };
  
      // // DELIVERY DISTANCE list
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_LIST_REQUEST:
      //   return {
      //     ...state,
      //     loading: true,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_LIST_SUCCESS:
      //   return {
      //     ...state,
      //     loading: false,
      //     list: payload.data,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_LIST_FAILURE:
      //   return {
      //     ...state,
      //     loading: false,
      //     errorMsg: payload,
      //     list: [],
      //   };
  
      // // DELIVERY DISTANCE delete
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_DELETE_REQUEST:
      //   return {
      //     ...state,
      //     loading: true,
      //     isStatusUpdate: false,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_DELETE_SUCCESS:
      //   return {
      //     ...state,
      //     loading: false,
      //     list: payload.data,
      //     isStatusUpdate: true,
      //   };
  
      // case vendorDropdownAction.DELIVERY_DISTANCE_RANGE_DELETE_FAILURE:
      //   return {
      //     ...state,
      //     loading: false,
      //     errorMsg: payload,
      //     list: [],
      //     isStatusUpdate: false,
      //   };

    default:
      return state;
  }
};
