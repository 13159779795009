import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const VET_LIST_REQUEST = "VET_LIST_REQUEST";
export const VET_LIST_SUCCESS = "VET_LIST_SUCCESS";
export const VET_LIST_FAILURE = "VET_LIST_FAILURE";

export const VET_DETAIL_REQUEST = "VET_DETAIL_REQUEST";
export const VET_DETAIL_SUCCESS = "VET_DETAIL_SUCCESS";
export const VET_DETAIL_FAILURE = "VET_DETAIL_FAILURE";

export const VET_STATUS_UPDATE_REQUEST = "VET_STATUS_UPDATE_REQUEST";
export const VET_STATUS_UPDATE_SUCCESS = "VET_STATUS_UPDATE_SUCCESS";
export const VET_STATUS_UPDATE_FAILURE = "VET_STATUS_UPDATE_FAILURE";

export const VET_UPDATE_REQUEST = "VET_UPDATE_REQUEST";
export const VET_UPDATE_SUCCESS = "VET_UPDATE_SUCCESS";
export const VET_UPDATE_FAILURE = "VET_UPDATE_FAILURE";

export const IMPORT_VETS_REQUEST = "IMPORT_VETS_REQUEST";
export const IMPORT_VETS_SUCCESS = "IMPORT_VETS_SUCCESS";
export const IMPORT_VETS_FAILURE = "IMPORT_VETS_FAILURE";

export const vetList = (setIsLoading, region, isIncomplete, status) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      setIsLoading(false);
      dispatch({ type: VET_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/vet/list/v1?regionName=${region}&incomplete=${isIncomplete}&status=${status}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VET_LIST_SUCCESS, payload: response.data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandling(dispatch, VET_LIST_FAILURE, error);
    }
  };
};

export const vetDetail = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_DETAIL_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/vet/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VET_DETAIL_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, VET_DETAIL_FAILURE, error);
    }
  };
};

export const updateVetStatus = ({ vetId, status }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_STATUS_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/update/status/vet/${vetId}/v1`;
      const response = await axios.put(
        url,
        { state: status },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: VET_STATUS_UPDATE_SUCCESS, payload: response.data });
      toast(`Status of vet is change.`)
    } catch (error) {
      errorHandling(dispatch, VET_STATUS_UPDATE_FAILURE, error);
    }
  };
};

export const updateVetProfile = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/vet/data/${id}/v1`;
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: VET_UPDATE_SUCCESS, payload: response.data });
      toast(`Vet updated successfully.`)
    } catch (error) {
      errorHandling(dispatch, VET_UPDATE_FAILURE, error);
    }
  };
};

export const importVets = (data, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "VET_STATUS_CHANGE" });
      dispatch({ type: IMPORT_VETS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/creat/vet/v1`;
      const response = await axios.post(
        url,
        { data },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: IMPORT_VETS_SUCCESS, payload: response.data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandling(dispatch, IMPORT_VETS_FAILURE, error);
    }
  };
};