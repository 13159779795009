const validations = {
  onlyNumberAllowed: /^[0-9]*$/,
  specialCharsNotAllowed: /^[a-zA-Z0-9\s]*$/,
  onlyLettersAllowed: /^[a-zA-Z\s]*$/,
  emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  mobileNumberRegex: /^$|^[789]\d{0,9}$/,
  pincodeRegex: /^[1-9][0-9]{0,5}$/, 
  adhaarNumberRegex: /^\d{0,12}$/
};

export default validations;
